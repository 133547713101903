<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1 class="mb-4">Töölaud</h1>

    <b-row v-if="sales">
      <b-col md="3">
        <StatBoard
          label="Tellimusi kokku"
          :value="sales.ordersTotal"
          variant="primary"
          icon="boxes"
        />
      </b-col>
      <b-col md="3">
        <StatBoard
          label="Tellimusi sel kuul"
          :value="sales.ordersMonth"
          variant="primary"
          icon="boxes"
        />
      </b-col>
      <b-col md="3">
        <StatBoard
          label="Tellimusi täna"
          :value="sales.ordersToday"
          variant="primary"
          icon="box"
        />
      </b-col>
      <b-col md="3">
        <StatBoard
          label="Tellimusi töös"
          :value="sales.ordersPending"
          variant="warning"
          icon="box-open"
        />
      </b-col>
    </b-row>
    <b-row v-if="sales">
      <b-col md="3">
        <StatBoard
          label="Müüki kokku"
          :value="sales.salesTotal"
          variant="success"
          icon="euro-sign"
          :isCurrency="true"
        />
      </b-col>
      <b-col md="3">
        <StatBoard
          label="Müüki sel kuul"
          :value="sales.salesThisMonth"
          variant="success"
          icon="euro-sign"
          :isCurrency="true"
        />
      </b-col>
      <b-col md="3">
        <StatBoard
          label="Müüki täna"
          :value="sales.salesToday"
          variant="success"
          icon="euro-sign"
          :isCurrency="true"
        />
      </b-col>
      <b-col md="3">
        <StatBoard
          label="Müüki töös"
          :value="sales.salesPending"
          variant="warning"
          icon="user-clock"
          :isCurrency="true"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-radio-group
            v-model="selectedType"
            :options="options"
            button-variant="outline-primary"
            size="sm"
            buttons
            @change="reloadChart"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="chart">
      <b-col>
        <OrdersChart
          :chartdata="{
            labels: chart.labels,
            datasets: [
              {
                label: 'Tellimused',
                data: chart.points,
                fill: false,
                borderColor: '#7a9b09',
                lineTension: 0.3,
              },
              {
                label: 'Tühistatud',
                data: chart.pointsFailed,
                fill: false,
                borderColor: '#e74a3b',
                lineTension: 0.3,
              },
            ],
          }"
          :options="{ maintainAspectRatio: false }"
        />
      </b-col>
    </b-row>
    <b-row v-if="chart">
      <b-col>
        <OrdersChart
          :chartdata="{
            labels: chart.labels,
            datasets: [
              {
                label: 'Müük',
                yAxisID: 'A',
                data: chart.pointsTotal,
                fill: false,
                borderColor: '#7a9b09',
                lineTension: 0.3,
              },
              {
                label: 'Marginal',
                yAxisID: 'B',
                data: chart.pointsMarginalSum,
                fill: false,
                borderColor: 'red',
                lineTension: 0.3,
              },
            ],
          }"
          :options="{ 
            maintainAspectRatio: false, 
            scales: {
              yAxes: [{
                id: 'A',
                type: 'linear',
                position: 'left',
              }, {
                id: 'B',
                type: 'linear',
                position: 'right',
              }]
            }
          }"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="chart && chart.shippingChart">
      <b-col>
        <ShippingChart
          :chartdata="{
            labels: chart.shippingChart.labels,
            datasets: [
              {
                label: 'Dataset 1',
                data: chart.shippingChart.data,
                backgroundColor: chart.shippingChart.colors,
              },
            ],
          }"
          :options="{
            responsive: true,
            maintainAspectRatio: true,
            animation: false,
          }"
        />
      </b-col>
      <b-col>
        <ShippingChart
          :chartdata="{
            labels: chart.paymentChart.labels,
            datasets: [
              {
                label: 'Dataset 1',
                data: chart.paymentChart.data,
                backgroundColor: chart.paymentChart.colors,
              },
            ],
          }"
          :options="{
            responsive: true,
            maintainAspectRatio: true,
            animation: false,
          }"
        />
      </b-col>
      <b-col>
        <ShippingChart
          :chartdata="{
            labels: chart.personTypeChart.labels,
            datasets: [
              {
                label: 'Dataset 1',
                data: chart.personTypeChart.data,
                backgroundColor: chart.personTypeChart.colors,
              },
            ],
          }"
          :options="{
            responsive: true,
            maintainAspectRatio: true,
            animation: false,
          }"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import StatBoard from "./StatBoard";
import OrdersChart from "./OrdersChart";
import ShippingChart from "./ShippingChart";
import { mapGetters } from "vuex";

export default {
  components: {
    StatBoard,
    OrdersChart,
    ShippingChart,
  },
  data() {
    return {
      selectedType: "day",
      options: [
        { text: "Päev", value: "day" },
        { text: "Nädal", value: "week" },
        { text: "Kuu", value: "month" },
      ],
      isLoading: false,
      sales: null,
      chart: null,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  mounted() {
    this.fetchSales();
    this.fetchOrdersChartData();
  },
  methods: {
    fetchSales() {
      this.isLoading = true;
      RestService.getStatisticsSales().then((response) => {
        this.sales = response.data.data;
        this.isLoading = false;
      });
    },
    fetchOrdersChartData() {
      RestService.getOrdersChartData().then((response) => {
        this.chart = response.data.data;
      });
    },
    reloadChart(type) {
      this.isLoading = true;
      this.chart = null;
      RestService.getOrdersChartData({
        type: type,
      }).then((response) => {
        this.isLoading = false;
        this.chart = response.data.data;
      });
    },
  },
};
</script>

<style lang="scss">
.card {
  &.border-left-primary {
    border-left: 0.25rem solid #4e73df;
  }

  &.border-left-warning {
    border-left: 0.25rem solid #f6c23e;
  }

  &.border-left-success {
    border-left: 0.25rem solid #1cc88a;
  }

  &.border-left-danger {
    border-left: 0.25rem solid #e74a3b;
  }
}
</style>
