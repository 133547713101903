<template>
  <editor
    v-model="text"
    api-key="no-api-key"
    :init="{
      plugins: 'code table media link fullscreen',
      image_title: false,
      image_dimensions: false,
      image_description: false,
      relative_urls: true,
      remove_script_host: false,
      convert_urls: true,
      toolbar: this.toolbar,
      fontsize_formats: '11px 12px 14px 16px 18px 24px 32px 36px 48px',
      menubar: this.showMenuBar,
      content_css: false,
    }"
  />
</template>

<script>
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/media';
import 'tinymce/plugins/fullscreen';

import Editor from "@tinymce/tinymce-vue";

export default {
  props: {
    value: {},
    basic: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Editor,
  },
  computed: {
    showMenuBar() {
      if (this.basic) {
        return false;
      }

      return true;
    },
    toolbar() {
      if (this.basic) {
        return "undo redo | bold italic";
      }

      return "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect | image | fullscreen";
    }
  },
  data() {
    return {
      text: this.value,
    };
  },
  methods: {
    update() {
      this.text = this.value;
    },
  },
  watch: {
    text(value) {
      this.$emit("input", value);
    },
  },
};
</script>
