import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./vuex";
import axios from "axios";
import localforage from "localforage";
import BootstrapVue from "bootstrap-vue";
import VueCurrencyFilter from "vue-currency-filter";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faSpinner,
  faFolder,
  faFile,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faEdit,
  faInfo,
  faSync,
  faEye,
  faTimes,
  faPlus,
  faCheck,
  faEuroSign,
  faUserClock,
  faBox,
  faBoxes,
  faBoxOpen,
  faTachometerAlt,
  faUsers,
  faStickyNote,
  faStream,
  faCloudUploadAlt,
  faDolly,
  faPiggyBank,
  faSearchDollar,
  faMapSigns,
  faAssistiveListeningSystems,
  faLanguage,
  faCubes,
  faSitemap,
  faBars,
  faCogs,
  faSearch,
  faExternalLinkAlt,
  faDownload,
  faFilter,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCheckSquare,
  faSquare,
  faCopy,
  faEnvelope,
  faBell,
} from "@fortawesome/free-regular-svg-icons";
import { faLaravel } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import vSelect from "vue-select";
import VueTimers from "vue-timers";
import Multiselect from "vue-multiselect";
import VueToast from "vue-toast-notification";

import "vue-toast-notification/dist/theme-sugar.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import "vue-select/dist/vue-select.css";
import "../node_modules/nprogress/nprogress.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-context/dist/css/vue-context.css";

import moment from "moment";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import VCalendar from "v-calendar";
import Clipboard from "v-clipboard";
import VueConfirmDialog from 'vue-confirm-dialog'

if (process.env.VUE_APP_SENTRY_DSN != "") {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}

window.Pusher = require("pusher-js");

Vue.config.productionTip = false;

axios.defaults.withCredentials = true;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.baseURL = process.env.VUE_APP_API_HOST + "/admin/api";

if (process.env.VUE_APP_API_BEFORE_TOKEN) {
  axios.defaults.headers.common["Authorization"] =
    process.env.VUE_APP_API_BEFORE_TOKEN;
}

Vue.prototype.$http = axios;

library.add(
  faUserSecret,
  faSpinner,
  faFolder,
  faFile,
  faInfo,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faCheckSquare,
  faSquare,
  faEdit,
  faSync,
  faEye,
  faTimes,
  faPlus,
  faCheck,
  faEuroSign,
  faUserClock,
  faBox,
  faBoxes,
  faBoxOpen,
  faTachometerAlt,
  faUsers,
  faStickyNote,
  faStream,
  faCloudUploadAlt,
  faDolly,
  faPiggyBank,
  faSearchDollar,
  faMapSigns,
  faAssistiveListeningSystems,
  faLanguage,
  faCubes,
  faSitemap,
  faBars,
  faCogs,
  faSearch,
  faDownload,
  faExternalLinkAlt,
  faCopy,
  faLaravel,
  faFilter,
  faExclamationTriangle,
  faEnvelope,
  faBell
);

localforage.config({
  driver: localforage.LOCALSTORAGE,
  storeName: "pureva_intra",
});

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("v-select", vSelect);
Vue.component("loader", require("./components/Loader.vue").default);
Vue.component("date-picker", require("./components/DatePicker.vue").default);
Vue.component("upload", require("./components/Upload.vue").default);
Vue.component("multiselect", Multiselect);

Vue.component(
  "tree-item",
  require("./components/treeView/TreeItem.vue").default
);

Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(BootstrapVue);

Vue.use(VueToast);

Vue.use(VueTimers);

Vue.use(VueCurrencyFilter, [
  {
    symbol: "",
    thousandsSeparator: " ",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "rear",
    symbolSpacing: false,
  },
  {
    name: "number",
    symbol: "",
    thousandsSeparator: " ",
    fractionCount: 0,
    fractionSeparator: ".",
    symbolPosition: "rear",
    symbolSpacing: false,
  },
  {
    name: "currency3",
    symbol: "",
    thousandsSeparator: " ",
    fractionCount: 3,
    fractionSeparator: ".",
    symbolPosition: "rear",
    symbolSpacing: false,
  },
]);

Vue.use(Clipboard);

Vue.use(VCalendar);

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

store.dispatch("global/beforePageLoad").then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
