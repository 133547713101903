import { SupplierOrders } from "../components";

export default [
  {
    path: "/orders/suppliers",
    component: SupplierOrders,
    name: "supplier.orders",
    meta: {
      guest: false,
      needsAuth: true,
    },
  }
];
