<template>
  <div class="loader text-center" :class="{ 'loading-container': overlay }">
    <Loader :loading="true" :color="color" :size="size"></Loader>
  </div>
</template>

<script>
import Loader from "vue-spinner/src/ClipLoader";

export default {
  props: ["overlay"],
  components: {
    Loader,
  },
  data() {
    return {
      color: "#414CFF",
      size: "100px",
    };
  },
};
</script>

<style lang="scss">
.loader {
  padding: 125px 0;

  &.loading-container {
    position: absolute;
    z-index: 5;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    padding: 0;

    .v-spinner {
      position: absolute;
      top: calc(50% - 50px);
      left: 0;
      right: 0;
    }
  }
}
</style>
