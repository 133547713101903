<template>
  <router-link
    :to="{ name: routeName }"
    v-slot="{ href, navigate, isExactActive }"
  >
    <div>
      <b-list-group-item
        variant="light"
        :active="isExactActive && !hasSubitems"
        :href="href"
        @click="navigate"
      >
        <span class="icon-badge mr-2">
          <font-awesome-icon :icon="icon" :style="{ color: iconColor }" />
        </span>
        <slot></slot>
      </b-list-group-item>
      <div class="subitems" v-if="hasSubitems">
        <slot name="subitems"></slot>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    routeName: {
      type: String,
    },
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconColor: {
      type: String,
      default: "#fff",
    },
  },
  computed: {
    hasSubitems() {
      return !!this.$slots.subitems;
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  .list-group-item {
    padding: 0.5rem 1.25rem;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .subitems {
    .list-group-item {
      padding: 0 0 0 2rem;
      border: none;
    }
  }

  .icon-badge {
    background: #414CFF;
    padding: 1px 5px;
    border-radius: 5px;
    width: 30px;
    display: inline-block;
    text-align: center;
  }

  .subitems {
    .icon-badge {
      padding: 3px;
      width: 24px;
      background: none;
    }
  }
}
</style>
