<template>
  <div class="bg-light border-right" id="sidebar-wrapper">
    <div class="sidebar-heading">
      <router-link :to="{ name: 'dashboard' }">
        <PurevaLogo class="logo"></PurevaLogo>
      </router-link>
    </div>

    <SidebarContent />
  </div>
</template>

<script>
import PurevaLogo from "../PurevaLogo";
import SidebarContent from "./SidebarContent.vue";

export default {
  components: {
    PurevaLogo,
    SidebarContent,
  },
};
</script>
