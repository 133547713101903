<template>
  <div>
    <h1 class="d-inline-block mr-3 float-left">
      {{ name }}
    </h1>

    <b-button
      class="d-block d-md-none float-right"
      size="sm"
      variant="outline-primary"
      @click.prevent="toggleOrdersSidebar"
    >
      <font-awesome-icon icon="bars" />
    </b-button>

    <div class="d-none d-sm-block" :class="{ 'd-block': showMenu }">
      <b-nav class="float-left" pills small>
        <b-nav-item
          :to="{
            name: 'order.new',
          }"
          :active="type == 'new'"
        >
          Uus tellimus
        </b-nav-item>
        <b-nav-item
          :to="{
            name: 'orders.mine',
          }"
          :active="type == 'mine'"
        >
          Minu tellimused
        </b-nav-item>
        <b-nav-item
          :to="{
            name: 'orders.status.type',
            params: { statusTypeId: 1 },
          }"
          :active="type == 'orders'"
        >
          Tellimused
        </b-nav-item>
        <b-nav-item
          :to="{
            name: 'orders.search',
          }"
          :active="type == 'search'"
        >
          Otsing
        </b-nav-item>
        <b-nav-item
          :to="{
            name: 'orders.waiting-office',
          }"
          :active="type == 'stock'"
        >
          Lattu saabuvad tooted
        </b-nav-item>
        <b-nav-item
          :to="{
            name: 'orders.attention',
          }"
          :active="type == 'attention'"
        >
          Tähelepanu vajavad tellimused
        </b-nav-item>
        <b-nav-item
          :to="{
            name: 'orders.unpaid',
          }"
          :active="type == 'unpaid-orders'"
        >
          Maksmata tellimused
        </b-nav-item>
      </b-nav>
    </div>

    <div class="clearfix"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    type: {
      type: String,
    },
    name: {
      type: String,
      default: "Tellimused",
    },
  },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    toggleOrdersSidebar() {
      this.showMenu = !this.showMenu
    }
  }
};
</script>
