var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[(_vm.isLoading)?_c('loader',{attrs:{"overlay":true}}):_vm._e(),_c('h1',{staticClass:"mb-4"},[_vm._v("Töölaud")]),(_vm.sales)?_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('StatBoard',{attrs:{"label":"Tellimusi kokku","value":_vm.sales.ordersTotal,"variant":"primary","icon":"boxes"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('StatBoard',{attrs:{"label":"Tellimusi sel kuul","value":_vm.sales.ordersMonth,"variant":"primary","icon":"boxes"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('StatBoard',{attrs:{"label":"Tellimusi täna","value":_vm.sales.ordersToday,"variant":"primary","icon":"box"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('StatBoard',{attrs:{"label":"Tellimusi töös","value":_vm.sales.ordersPending,"variant":"warning","icon":"box-open"}})],1)],1):_vm._e(),(_vm.sales)?_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('StatBoard',{attrs:{"label":"Müüki kokku","value":_vm.sales.salesTotal,"variant":"success","icon":"euro-sign","isCurrency":true}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('StatBoard',{attrs:{"label":"Müüki sel kuul","value":_vm.sales.salesThisMonth,"variant":"success","icon":"euro-sign","isCurrency":true}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('StatBoard',{attrs:{"label":"Müüki täna","value":_vm.sales.salesToday,"variant":"success","icon":"euro-sign","isCurrency":true}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('StatBoard',{attrs:{"label":"Müüki töös","value":_vm.sales.salesPending,"variant":"warning","icon":"user-clock","isCurrency":true}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('b-form-group',[_c('b-form-radio-group',{attrs:{"options":_vm.options,"button-variant":"outline-primary","size":"sm","buttons":""},on:{"change":_vm.reloadChart},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1)],1)],1),(_vm.chart)?_c('b-row',[_c('b-col',[_c('OrdersChart',{attrs:{"chartdata":{
          labels: _vm.chart.labels,
          datasets: [
            {
              label: 'Tellimused',
              data: _vm.chart.points,
              fill: false,
              borderColor: '#7a9b09',
              lineTension: 0.3,
            },
            {
              label: 'Tühistatud',
              data: _vm.chart.pointsFailed,
              fill: false,
              borderColor: '#e74a3b',
              lineTension: 0.3,
            },
          ],
        },"options":{ maintainAspectRatio: false }}})],1)],1):_vm._e(),(_vm.chart)?_c('b-row',[_c('b-col',[_c('OrdersChart',{attrs:{"chartdata":{
          labels: _vm.chart.labels,
          datasets: [
            {
              label: 'Müük',
              yAxisID: 'A',
              data: _vm.chart.pointsTotal,
              fill: false,
              borderColor: '#7a9b09',
              lineTension: 0.3,
            },
            {
              label: 'Marginal',
              yAxisID: 'B',
              data: _vm.chart.pointsMarginalSum,
              fill: false,
              borderColor: 'red',
              lineTension: 0.3,
            },
          ],
        },"options":{ 
          maintainAspectRatio: false, 
          scales: {
            yAxes: [{
              id: 'A',
              type: 'linear',
              position: 'left',
            }, {
              id: 'B',
              type: 'linear',
              position: 'right',
            }]
          }
        }}})],1)],1):_vm._e(),(_vm.chart && _vm.chart.shippingChart)?_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('ShippingChart',{attrs:{"chartdata":{
          labels: _vm.chart.shippingChart.labels,
          datasets: [
            {
              label: 'Dataset 1',
              data: _vm.chart.shippingChart.data,
              backgroundColor: _vm.chart.shippingChart.colors,
            },
          ],
        },"options":{
          responsive: true,
          maintainAspectRatio: true,
          animation: false,
        }}})],1),_c('b-col',[_c('ShippingChart',{attrs:{"chartdata":{
          labels: _vm.chart.paymentChart.labels,
          datasets: [
            {
              label: 'Dataset 1',
              data: _vm.chart.paymentChart.data,
              backgroundColor: _vm.chart.paymentChart.colors,
            },
          ],
        },"options":{
          responsive: true,
          maintainAspectRatio: true,
          animation: false,
        }}})],1),_c('b-col',[_c('ShippingChart',{attrs:{"chartdata":{
          labels: _vm.chart.personTypeChart.labels,
          datasets: [
            {
              label: 'Dataset 1',
              data: _vm.chart.personTypeChart.data,
              backgroundColor: _vm.chart.personTypeChart.colors,
            },
          ],
        },"options":{
          responsive: true,
          maintainAspectRatio: true,
          animation: false,
        }}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }