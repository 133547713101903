<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1 class="mb-4">Kassa</h1>

    <b-card class="mb-5">
      Tere tulemast
    </b-card>

    <b-card>
      <b-row>
        <b-col>
          <b-table-simple bordered>
            <b-tr>
              <b-th>Toode</b-th>
              <b-th class="text-center">Kogus</b-th>
              <b-th class="text-center">Summa</b-th>
            </b-tr>
            <b-tr>
              <b-td>
                Trendnet TPE-115GI PoE-adapter <br>
                <small><strong>Kood: </strong>166227555</small>
              </b-td>
              <b-td class="text-center">
                1
              </b-td>
              <b-td class="text-center">
                100.00&nbsp;&euro;
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>
                LevelOne POS-3000 võrgujagur Must Power over Ethernet tugi <br>
                <small><strong>Kood: </strong>166227555</small>
              </b-td>
              <b-td class="text-center">
                1
              </b-td>
              <b-td class="text-center">
                100.00&nbsp;&euro;
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <b-form-group class="mb-1" label="Eesnimi">
                <b-form-input></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="mb-1" label="Perenimi">
                <b-form-input></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group class="mb-1" label="Email">
                <b-form-input></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="mb-1" label="Telefon">
                <b-form-input></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button class="mt-3" variant="primary" size="lg" block>Kinnita</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  mounted() {
    
  },
  methods: {
  },
};
</script>

<style lang="scss">

</style>
