export default {
  preLoading: true,
  connection: true,
  isTabActive: true,
  languages: [
    {
      locale: "et",
      name: "Eesti",
    },
    {
      locale: "en",
      name: "English",
    },
    {
      locale: "de",
      name: "German",
    },
  ],
  showSidebar: false,
};
