<template>
  <div class="list-group list-group-flush">
    <sidebar-item routeName="dashboard" icon="tachometer-alt">
      Töölaud
    </sidebar-item>
    <sidebar-item routeName="pages" icon="sticky-note">
      Veeb

      <template v-slot:subitems>
        <sidebar-item routeName="pages" icon="angle-right" iconColor="#414CFF">
          Lehed
        </sidebar-item>

        <sidebar-item routeName="menus" icon="angle-right" iconColor="#414CFF">
          Menüüd
        </sidebar-item>

        <sidebar-item
          routeName="banners"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Bännerid
        </sidebar-item>

        <sidebar-item
          routeName="translations"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Tõlked
        </sidebar-item>
      </template>
    </sidebar-item>

    <sidebar-item routeName="orders" icon="boxes">
      Müük
      <template v-slot:subitems>
        <sidebar-item routeName="orders" icon="angle-right" iconColor="#414CFF">
          Tellimused
        </sidebar-item>

        <sidebar-item
          routeName="orderStatuses"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Tellimuste staatused
        </sidebar-item>

        <sidebar-item
          routeName="order.new"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Uus tellimus
        </sidebar-item>

        <sidebar-item routeName="offers" icon="angle-right" iconColor="#414CFF" v-if="false">
          Pakkumised
        </sidebar-item>
        
        <sidebar-item
          routeName="discount-codes"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Sooduskoodid
        </sidebar-item>
      </template>
    </sidebar-item>

    <sidebar-item routeName="products-search" icon="box-open">
      Tooted
      <template v-slot:subitems>
        <sidebar-item
          routeName="products-search"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Andmebaasi otsing
        </sidebar-item>
        <sidebar-item
          routeName="products"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Indexi otsing
        </sidebar-item>

        <sidebar-item
          routeName="productStockStatuses"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Lao staatused
        </sidebar-item>
        <sidebar-item
          routeName="product-tracking"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Toodete jälgimine
        </sidebar-item>
        <sidebar-item
          routeName="products.new"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Uus toode
        </sidebar-item>
        <sidebar-item
          routeName="products-auto-matching"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Duplikaatide otsimine
        </sidebar-item>
        <sidebar-item
          routeName="products-merge"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Toodete merge
        </sidebar-item>
        <sidebar-item
          routeName="fixing-products"
          icon="angle-right"
          iconColor="#414CFF"
          v-if="false"
        >
          Toodete parandamine
        </sidebar-item>
      </template>
    </sidebar-item>

    <sidebar-item routeName="users" icon="users">
      Kliendid
    </sidebar-item>

    <sidebar-item routeName="suppliers" icon="map-signs">
      Mappimine
      <template v-slot:subitems>
        <sidebar-item
          routeName="suppliers"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Tarnijad
        </sidebar-item>
        <sidebar-item routeName="brands" icon="angle-right" iconColor="#414CFF">
          Brändid
        </sidebar-item>
        <sidebar-item
          routeName="catalog"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Kataloog
        </sidebar-item>
        <sidebar-item
          routeName="categories"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Kategooriad
        </sidebar-item>
        <sidebar-item
          routeName="product-categories"
          icon="angle-right"
          iconColor="#414CFF"
          v-if="false"
        >
          Toodete kat.
        </sidebar-item>
        <sidebar-item
          routeName="deliveryMapper"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Delivery mapper
        </sidebar-item>
        <sidebar-item
          routeName="categoryMapper"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Kategooria mapper
        </sidebar-item>
      </template>
    </sidebar-item>

    <sidebar-item routeName="adminUsers" icon="cogs">
      Seaded
      <template v-slot:subitems>
        <sidebar-item
          routeName="adminUsers"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Administraatorid
        </sidebar-item>

        <sidebar-item
          routeName="shippingZones"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Transport
        </sidebar-item>

        <sidebar-item
          routeName="dashboard.couriers"
          icon="angle-right"
          iconColor="#414CFF"
          v-if="false"
        >
          Kullerid
        </sidebar-item>

        <sidebar-item routeName="leases" icon="angle-right" iconColor="#414CFF">
          Järelmaks
        </sidebar-item>

        <sidebar-item routeName="emails" icon="angle-right" iconColor="#414CFF">
          Emailid
        </sidebar-item>

        <sidebar-item
          routeName="search-synonyms"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Otsingu sünonüümid
        </sidebar-item>

        <sidebar-item
          routeName="import-status"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Impordi staatus
        </sidebar-item>

        <sidebar-item
          routeName="import-logs"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Impordi logid
        </sidebar-item>

        <div>
          <b-list-group-item
            variant="light"
            :href="horizonLink"
            target="_blank"
          >
            <span class="icon-badge mr-2">
              <font-awesome-icon
                icon="angle-right"
                :style="{ color: '#414CFF' }"
              />
            </span>
            Laravel Horizon
          </b-list-group-item>
        </div>
      </template>
    </sidebar-item>
  </div>
</template>

<script>
import SidebarItem from "./SidebarItem.vue";

export default {
  components: {
    SidebarItem,
  },
  data() {
    return {
      horizonLink: `${process.env.VUE_APP_API_HOST}/admin/horizon`,
    };
  },
};
</script>
