<template>
  <div class="position-relative">
    <loader :overlay="true" v-if="isLoading" />

    <div v-if="!product && !isLoading" class="not-found">
      Toodet ei leitud!
    </div>

    <template v-if="product">
      <h2 class="mb-3">
        <b-button
          variant="success"
          class="float-right"
          @click.prevent="saveProduct"
        >
          Salvesta toode
        </b-button>
        Muuda toodet
        <template v-if="product">
          - (ID: {{ product.id }})
          <b-button
            :href="product.product_url"
            target="_blank"
            variant="outline-primary"
            size="sm"
            class="ml-2"
          >
            Ava veebis
          </b-button>
          <br />
          <small>{{ product.name }}</small>
        </template>
      </h2>

      <b-row>
        <b-col cols="5">
          <b-card header="Üldine info">
            <ProductCodes
              :product="product"
              @reloadProduct="fetchProduct"
            ></ProductCodes>

            <b-row>
              <b-col>
                <ul class="list-unstyled">
                  <li><strong>SKU:</strong> {{ product.sku }}</li>
                  <li v-for="code in productEans" :key="code.id">
                    <strong> {{ code.type }}: </strong>
                    {{ code.code }}
                  </li>
                  <li v-for="code in productMpns" :key="code.id">
                    <strong> {{ code.type }}: </strong>
                    {{ code.code }}
                  </li>
                </ul>
              </b-col>
              <b-col>
                <ul class="list-unstyled">
                  <li v-for="code in productSupplierCodes" :key="code.id">
                    <strong> {{ code.type }}: </strong>
                    {{ code.code }}
                  </li>
                </ul>
              </b-col>
            </b-row>

            <b-form-group class="mb-1" label="Tootja Peamine">
              <v-select
                v-model="form.manufacturer_data"
                :filterable="false" 
                :options="manufacturerSearchOptions" 
                @search="searchManufacturer"
              ></v-select>
            </b-form-group>

            <b-form-group class="mb-1" label="Tootja">
              <b-form-input
                v-model="product.manufacturer"
                size="sm"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="mb-1" label="Kategooria">
              <v-select
                v-if="flatCategories.length"
                v-model="form.categories"
                :options="flatCategories"
                :reduce="(item) => item.code"
                multiple
              ></v-select>
              <b-spinner v-else small></b-spinner>
            </b-form-group>

            <b-form-group class="mb-1" label="Soovituslik hind">
              <b-form-input
                v-model="product.recommended_price"
                size="sm"
              ></b-form-input>
            </b-form-group>

            <b-form-group class="mb-1" label="Miinimum kogus">
              <b-form-input
                v-model.number="product.minimum_qty"
                size="sm"
              ></b-form-input>
            </b-form-group>

            <b-form-group class="mb-1" label="Toote kaal (kg)">
              <b-form-input
                type="number"
                v-model="product.weight"
                size="sm"
              ></b-form-input>
            </b-form-group>

            <b-form-group class="mb-1" label="Mahub pakiautomaati?">
              <b-form-radio-group
                v-model="product.is_valid_parcel"
                :options="[{text: 'Puudub', value: null}, {text: 'Ei', value: false}, {text: 'Jah', value: true}, ]"
                name="radio-inline"
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group class="mb-1">
              <b-form-checkbox v-model="product.is_featured" switch>
                Kuva avalehel
              </b-form-checkbox>
            </b-form-group>

            <b-form-group class="mb-1">
              <b-form-checkbox v-model="product.show_in_fb" switch>
                Kuva facebookis
              </b-form-checkbox>
            </b-form-group>

            <b-form-group class="mb-1">
              <b-form-checkbox v-model="product.is_category_ready" switch>
                Kategooria valmis
              </b-form-checkbox>
            </b-form-group>

            <b-form-group class="mb-1">
              <b-form-checkbox v-model="product.is_used" switch>
                Kasutatud toode
              </b-form-checkbox>
            </b-form-group>

            <b-form-group class="mb-1">
              <b-form-checkbox v-model="product.is_disabled" switch>
                Is disabled
              </b-form-checkbox>
            </b-form-group>

          </b-card>
          <b-card class="mt-3" header="Toote pildid" no-body>
            <div class="p-2">
              <upload
                :isLoading="isUploadLoading"
                @handleUpload="handleFileUpload"
              />
              <b-list-group v-if="product.images.length">
                <draggable v-model="product.images" group="pImages">
                  <b-list-group-item
                    v-for="image in product.images"
                    :key="image.original"
                    class="p-1"
                  >
                    <img
                      :src="image.url"
                      alt=""
                      class="img-fluid"
                      style="max-width: 100px;"
                    />
                    <b-button
                      class="float-right"
                      variant="danger"
                      size="sm"
                      @click.prevent="deleteImage(image.original)"
                    >
                      Kustuta
                    </b-button>
                    <b-button
                      :href="image.url"
                      class="float-right mr-2"
                      variant="primary"
                      size="sm"
                      target="_blank"
                    >
                      Ava
                    </b-button>
                  </b-list-group-item>
                </draggable>
              </b-list-group>
            </div>
          </b-card>
        </b-col>
        <b-col cols="7">
          <b-card no-body>
            <b-card-header class="p-2">
              <div class="d-inline-block" style="vertical-align: middle;">
                Laod
              </div>
              <div class="float-right">
                <b-button
                  v-b-modal.product-stocks-info-1
                  class="mr-1"
                  size="sm"
                  pill
                  variant="outline-primary"
                >
                  <font-awesome-icon class="mx-1" icon="info" />
                </b-button>
              </div>
            </b-card-header>

            <b-modal
              id="product-stocks-info-1"
              centered
              hide-footer
              size="xl"
              title="Laod"
            >
              <b-table-simple bordered responsive small>
                <b-tr variant="primary">
                  <b-th>Ladu</b-th>
                  <b-th>Tootja</b-th>
                  <b-th>Nimi</b-th>
                  <b-th>Import ID</b-th>
                </b-tr>
                <b-tr
                  v-for="stock in product.stocks"
                  :stock="stock"
                  :key="`info-${stock.id}`"
                >
                  <b-td>{{ stock.supplier.name }}</b-td>
                  <b-td>{{ stock.original_manufacturer }}</b-td>
                  <b-td>{{ stock.original_name }}</b-td>
                  <b-td>{{ stock.import_id }}</b-td>
                </b-tr>
              </b-table-simple>
            </b-modal>

            <div class="p-1">
              <b-table-simple bordered responsive small>
                <b-tr variant="primary">
                  <b-th style="width: 15%;">Ladu</b-th>
                  <b-th style="width: 5%;" class="text-center">Net</b-th>
                  <b-th style="width: 20%;" class="text-center">Hind</b-th>
                  <b-th style="width: 15%;" class="text-center">Originaal</b-th>
                  <b-th style="width: 10%;" class="text-center">Laoseis</b-th>
                  <b-th class="text-center">Tarne</b-th>
                  <b-th style="width: 5%;">Ajalugu</b-th>
                  <b-th style="width: 5%;" class="text-right">
                    Disabled
                  </b-th>
                </b-tr>
                <StockRow
                  v-for="stock in product.stocks"
                  :stock="stock"
                  :key="stock.id"
                  @deleteStock="handleStockDelete"
                />
              </b-table-simple>

              <b-button
                variant="primary"
                size="sm"
                @click.prevent="showNewStockForm"
                v-if="!showStockForm"
              >
                Lisa uus ladu
              </b-button>
              <b-button
                v-else
                variant="warning"
                size="sm"
                @click.prevent="hideNewStockForm"
                class="mb-3"
              >
                Katkesta
              </b-button>

              <div v-if="showStockForm">
                <b-table-simple responsive small bordered>
                  <b-tr variant="primary">
                    <b-th>Ladu</b-th>
                    <b-th>Hind</b-th>
                    <b-th>Laoseis</b-th>
                    <b-th>Tarne</b-th>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      <b-form-select
                        v-if="suppliersSelect.length"
                        v-model="newStockForm.supplier_id"
                        :options="suppliersSelect"
                        size="sm"
                      ></b-form-select>
                    </b-td>
                    <b-td>
                      <b-form-input
                        name="nsmanual_price"
                        autocomplete="false"
                        v-model="newStockForm.manual_price"
                        size="sm"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        name="nsin_stock"
                        autocomplete="false"
                        v-model="newStockForm.in_stock"
                        size="sm"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-select
                        v-if="stockStatusesSelect.length"
                        v-model="newStockForm.stock_status_id"
                        :options="stockStatusesSelect"
                        size="sm"
                      ></b-form-select>
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </div>
            </div>
          </b-card>

          <b-card header="Tarnija kategooriad" class="mt-3">
            <b-table-simple bordered small class="mb-0">
              <b-tr variant="primary">
                <b-th>Tarnija</b-th>
                <b-th>Kategooria</b-th>
              </b-tr>
              <b-tr v-for="stock in product.stocks" :key="`ct-${stock.id}`">
                <b-td>{{ stock.supplier.name }}</b-td>
                <b-td>{{ stock.category }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-card>

          <b-card
            header="Danger zone"
            bg-variant="danger"
            body-bg-variant="light"
            text-variant="light"
            class="mt-3"
          >
            <b-button
              class="float-left"
              @click="$bvModal.show('delete-product-modal')"
              variant="danger"
              >Kustuta toode</b-button
            >

            <b-button
              class="float-right"
              @click.prevent="createDuplicateProduct"
              variant="warning"
            >
              Koosta duplikaat toode
            </b-button>

            <b-modal
              id="delete-product-modal"
              hide-footer
              title="Kustuta toode"
            >
              <div class="d-block text-center">
                Toote kustutamiseks sisestage toote ID: <b>{{ product.id }}</b>
                <br />
                <b-form-group>
                  <b-form-input
                    v-model="deleteProductId"
                    size="sm"
                  ></b-form-input>
                </b-form-group>

                <b-button variant="danger" @click.prevent="deleteProduct">
                  Kustuta toode
                </b-button>
              </div>
            </b-modal>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-card>
            <template #header>
              <div>
                Tõlked
                <b-dropdown
                  class="float-right"
                  variant="primary"
                  size="sm"
                  text="Lisa Tõlge"
                >
                  <b-dropdown-item
                    v-for="language in languages"
                    :key="language.locale"
                    @click.prevent="addNewLanguage(language.locale)"
                    :disabled="false"
                  >
                    {{ language.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <div
              v-for="translation in product.translations"
              :key="translation.id"
              class="language"
            >
              <div
                class="deletable-language"
                v-if="isLanguageDeleted(translation.locale)"
              >
                This language is marked for delete <br />
                <b-button
                  variant="warning"
                  @click.prevent="restoreLanguage(translation.locale)"
                  >Restore</b-button
                >
              </div>
              <h4>
                {{ getLocaleName(translation.locale) }}
                <b-button
                  variant="danger"
                  size="sm"
                  @click.prevent="removeLanguage(translation.locale)"
                >
                  Kustuta tõlge
                </b-button>
              </h4>
              <b-form-group label="Nimi" class="mb-1">
                <b-form-input
                  v-model="translation.name"
                  size="sm"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Meta sõnad" class="mb-1">
                <b-form-tags
                  v-model="translation.keywords"
                  size="sm"
                ></b-form-tags>
              </b-form-group>
              <b-form-group label="Lühikirjeldus" class="mb-3">
                <b-button
                  variant="outline-primary"
                  size="sm"
                  v-if="translation.short_description === null"
                  @click.prevent="addShortDesc(translation.id)"
                >
                  Lisa lühikirjeldus
                </b-button>
                <Editor
                  v-model="translation.short_description"
                  v-if="!isLoading && translation.short_description !== null"
                  :basic="true"
                />
              </b-form-group>
              <b-form-group label="Kirjeldus" class="mb-3">
                <Editor v-model="translation.description" v-if="!isLoading" />
              </b-form-group>
              <hr />
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-card header="Toote info" class="mt-3">
            <b-table-simple small bordered>
              <b-tr v-for="item in product.attributeValues" :key="item.id">
                <b-td>
                  {{ item.attribute.name }}
                </b-td>
                <b-td>
                  {{ item.value }}
                </b-td>
              </b-tr>
            </b-table-simple>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import StockRow from "./productEdit/StockRow";
import ProductCodes from "./productEdit/ProductCodes";
import Editor from "@/components/Editor";
import { mapActions, mapGetters } from "vuex";
import { errorMessage, successMessage } from "@/lib/messages";
import RestService from "@/lib/RestService";
import draggable from "vuedraggable";
import debounce from "lodash/debounce";

export default {
  components: {
    StockRow,
    Editor,
    draggable,
    ProductCodes,
  },
  data() {
    return {
      showStockForm: false,
      isUploadLoading: false,
      form: {
        deletable_translations: [],
        categories: [],
        manufacturer_data: null,
      },
      newStockForm: {
        supplier_id: 6,
        manual_price: null,
        in_stock: 1,
        stock_status_id: null,
      },
      newStorageForm: {
        price: null,
        ordered_place: null,
        qty: 1,
        is_in_storage: false,
      },
      isLoading: true,
      product: null,
      text: null,
      deleteProductId: null,
      icecatDownloadId: null,
      possibleIcecatIds: null,
      manufacturerSearchOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
      categories: "categories/categories",
      stockStatusesSelect: "generic/stockStatusesSelect",
      suppliersSelect: "generic/suppliersSelect",
    }),
    flatCategories() {
      let categories = [];

      if (this.categories) {
        categories = this.makeCategoryList(this.categories);
      }

      return categories;
    },
    productEans() {
      if (!this.product) {
        return [];
      }

      return this.product.codes.filter((c) => c.type == 'gtin-14');
    },
    productMpns() {
      if (!this.product) {
        return [];
      }

      return this.product.codes.filter((c) => c.type == 'mpn');
    },
    productSupplierCodes() {
      if (!this.product) {
        return [];
      }

      return this.product.codes.filter((c) => c.type != 'gtin-14' && c.type != 'mpn');
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.product = null;
    this.deleteProductId = null;
    this.icecatDownloadId = null;
    this.possibleIcecatIds = null;

    this.fetchProduct(to.params.productId);

    next();
  },
  mounted() {
    this.fetchCategories();
    this.fetchProductStockStatuses();
    this.fetchSuppliers();
    this.fetchProduct();
  },
  methods: {
    ...mapActions({
      fetchCategories: "categories/fetchCategories",
      fetchProductStockStatuses: "generic/fetchProductStockStatuses",
      fetchSuppliers: "generic/fetchSuppliers",
    }),
    setProductData(response) {
      this.product = response.data.data;

      this.form.manufacturer_data = null;
      if (this.product.productManufacturer) {
        this.form.manufacturer_data = {
          label: this.product.productManufacturer.name,
          value: this.product.productManufacturer.id,
        }
      }

      this.form.categories = [];
      this.product.categories.data.forEach((cat) => {
        this.form.categories.push(cat.id);
      });
    },
    fetchProduct(productId = null) {
      this.isLoading = true;
      RestService.getProduct(productId || this.$route.params.productId)
        .then((response) => {
          this.setProductData(response);

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    addNewLanguage(locale) {
      if (this.product.translations.filter((t) => t.locale == locale).length) {
        errorMessage("Viga", "See keel on juba lisatud");
        return;
      }

      this.product.translations.push({
        locale: locale,
        name: null,
        description: null,
        short_description: null,
      });
    },
    addShortDesc(translationId) {
      let translationIndex = this.product.translations.findIndex(
        (t) => t.id == translationId
      );
      this.product.translations[translationIndex]["short_description"] = "";
    },
    getLocaleName(locale) {
      let language = this.languages.find((l) => l.locale == locale);

      if (!language) {
        return locale;
      }

      return language.name;
    },
    isLanguageDeleted(locale) {
      if (!this.form.deletable_translations.includes(locale)) {
        return false;
      }

      return true;
    },
    removeLanguage(locale) {
      if (this.form.deletable_translations.includes(locale)) {
        return null;
      }
      this.form.deletable_translations.push(locale);
    },
    restoreLanguage(locale) {
      if (!this.form.deletable_translations.includes(locale)) {
        return null;
      }

      this.form.deletable_translations = this.form.deletable_translations.filter(
        (l) => l != locale
      );
    },
    saveProduct() {
      this.isLoading = true;

      let payload = { ...this.product, ...this.form };

      if (this.showStockForm) {
        payload.new_stock = this.newStockForm;
      }

      RestService.postSaveProduct(this.product.id, payload)
        .then((response) => {
          this.setProductData(response);

          this.showStockForm = false;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    makeCategoryList(items, name = "") {
      let list = [];

      items.forEach((item) => {
        list.push({
          label: `${name}${item.name}`,
          code: item.id,
        });

        let childList = [];
        if (item.children.length) {
          childList = this.makeCategoryList(
            item.children,
            `${name}${item.name} > `
          );
        }

        list = list.concat(childList);
      });

      return list;
    },
    handleFileUpload(files) {
      let formData = new FormData();

      files.forEach((file) => {
        formData.append("files[]", file);
      });

      this.isUploadLoading = true;

      RestService.postProductUploadImages(this.product.id, formData)
        .then((response) => {
          this.setProductData(response);

          this.isUploadLoading = false;
        })
        .catch(() => {
          this.isUploadLoading = false;
        });
    },
    deleteImage(hash) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postProductDeleteImage(this.product.id, hash)
              .then((response) => {
                this.setProductData(response);
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
        },
      });
    },
    handleStockDelete(stockId) {
      this.$confirm({
        message: `Are you sure you want to delete that?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postProductDeleteStock(this.product.id, stockId)
              .then((response) => {
                this.setProductData(response);
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
        },
      });
    },
    showNewStockForm() {
      this.showStockForm = true;
    },
    hideNewStockForm() {
      this.showStockForm = false;
    },
    deleteProduct() {
      this.isLoading = true;
      RestService.postDeleteProduct(this.product.id, {
        verification: this.deleteProductId,
      })
        .then(() => {
          this.isLoading = false;
          successMessage("Teade", "Toode kustutatakse lähiajal ära.");
          this.$bvModal.hide("delete-product-modal");
          this.deleteProductId = null;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    downloadIcecatDataById() {
      this.isLoading = true;
      RestService.postProductDownloadIcecatData({
        id: this.product.id,
        icecat_id: this.icecatDownloadId,
      })
        .then(() => {
          this.isLoading = false;
          successMessage("Teade", "Toote andmeid üritatakse lähiajal tõmmata!");
          this.$bvModal.hide("icecat-product-modal");
          this.icecatDownloadId = null;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    downloadIcecatData() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postProductDownloadIcecatData({
              id: this.product.id,
            })
              .then(() => {
                this.isLoading = false;
                successMessage(
                  "Teade",
                  "Toote andmeid üritatakse lähiajal tõmmata!"
                );
              })
              .catch((error) => {
                this.isLoading = false;
                if (error.response.data.message) {
                  errorMessage("Viga!", error.response.data.message);
                }
              });
          }
        },
      });
    },
    addNewStorageEntries() {
      this.isLoading = true;
      RestService.postProductAddNewStorageEntries(
        this.product.id,
        this.newStorageForm
      )
        .then(() => {
          this.fetchProduct();
          successMessage("Teade", "Tooted lisatud!");
          this.$bvModal.hide("product-storage-add");
          this.newStorageForm = {
            price: null,
            ordered_place: null,
            qty: 1,
          };
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    createDuplicateProduct() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postProductCreateDuplicate(this.product.id)
              .then((response) => {
                this.isLoading = false;
                this.$router.push({
                  name: "products.edit",
                  params: { productId: response.data.data.id },
                });
              })
              .catch((error) => {
                this.isLoading = false;
                if (error.response.data.message) {
                  errorMessage("Viga!", error.response.data.message);
                }
              });
          }
        },
      });
    },
    searchManufacturer(search, loading) {
      if (search.length) {
        loading(true);
        this.searchManufacturerCall(loading, search, this);
      }
    },
    searchManufacturerCall: debounce((loading, search, vm) => {
      RestService.getBrandManufacturerSearch({
        search: search
      })
        .then((response) => {
          vm.manufacturerSearchOptions = response.data.items
          loading(false);
        })
        .catch((error) => {
          loading(false);
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    }, 350)
  },
};
</script>

<style lang="scss">
.language {
  position: relative;

  .deletable-language {
    position: absolute;
    left: -5px;
    right: -5px;
    top: -5px;
    bottom: -5px;
    background-color: rgba(255, 0, 0, 0.6);
    z-index: 99;
    color: #fff;
    text-align: center;
    padding-top: 75px;
    font-size: 42px;
  }
}

.not-found {
  font-size: 48px;
  text-align: center;
  padding: 60px 0;
  color: lightgray;
  text-transform: uppercase;
}
</style>
